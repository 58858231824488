
.blogHdr {
    background-color: white;
    height: 40px;
    border-bottom: 2px solid lightgray;
}

.clrButton {
    background: none;
	color: inherit;
	border: none;
	padding-top: 5px;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.button:focus {
    outline: none;
}

.blogM {
    padding:12px 0 14px 0; 
    border-bottom: 2px solid #172734; 
}

.commentM {
    padding:12px 15px 100px 0; 
}

.w1HLFFlu {
    height:auto;
    margin-bottom:8px;
    position:relative;
}

.w1HLFFlc {
    height:auto;
    margin-left:40px;
    position:relative
}

.w1HLFFlr {
    height:auto;
    margin-left:20px;
    position:relative
}

._37iC3bl {
    display: flex;
    align-items: center;
    max-width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
}

._37iC3blg {
    display: flex;
    align-items: center;
    max-width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
    padding-bottom: 10px;
}

.avatar {
    border: none !important;
    float: left;
    cursor: pointer;
    padding-left: 20px;
}

.avatar34 {
    height: 40px; 
    display: inline-block;
    cursor: pointer;

}

.avtrimg {
    border-radius: 50%;
    height: 40px; 
    width: 40px; 
    border:none;
    object-fit: cover;
    cursor: pointer;
    z-index: 100;
}

.avtrimgcmt {
    border-radius: 50%;
    height: 30px; 
    width: 30px; 
    border:none;
    object-fit: cover;
    cursor: pointer;
}

._1lxVetIX {
    display: inlineFlex; 
    flex-direction: column;
    padding-left: 20px; 
    align-items: flex-start; 
    float:left;
    color: #ffffff;
}

._3BGj4iHx {
    display: flex;
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    white-space: pre-wrap;
    cursor: pointer;
}

._3BGj4iHy {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    white-space: pre-wrap;
    cursor: pointer;
}

.authorct {
    color: #ffffff;
    letter-spacing: -0.32px;
    font-weight: normal;
    font-size: 11px;
    line-height: 19px;
    white-space: pre-wrap;
}

.authorcc {
    color: #676E7A;
    letter-spacing: -0.32px;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    white-space: pre-wrap;
    padding-left: 14px;
}

.postcmc {
    position:absolute;
    top:-10px;
    right: 2px;
}

.postcmc_grp {
    position:absolute;
    top:10px;
    right: 2px;
}

.blockcmc {
    position:absolute;
    top:0px;
    right: 2px;
}

.profilepostcmc {
    position:absolute;
    top:-10px;
    right: -10px;
}
.profilepostcmcblk {
    position:absolute;
    top:12px;
    right: -10px;
}


.locmenu {
    position:absolute;
    right: -10px;
}

.contentbody {
    font-size:14px;
    line-height:18px;
    letter-spacing:-0.12px;
}

.commcontentbody {
    font-size:12px;
    line-height:16px;
    letter-spacing:-0.32px;
    padding-left: 60px;
    padding-right: 10px;
    padding-top: 4px;
    margin-bottom: 0rem;
}

.replycontentbody {
    font-size:12px;
    line-height:16px;
    letter-spacing:-0.32px;
    padding-left: 50px;
    padding-right: 10px;
    padding-top: 4px;
    margin-bottom: 0rem;
}

.truncateltc {
    white-space:pre-wrap;
    display: block; 
    overflow: hidden; 
    text-overflow: ellipsis;
} 
    
.postac {
    padding: 8px 0px 20px; 
    position: relative;
}
.postacc {
    padding: 0px 0px 20px 60px; 
    position: relative;
}

.postacr {
    padding: 0px 0px 20px 50px; 
    position: relative;
}

._3LHT9vBz {
    /* display: inline-block; */
    min-width: 50px;
    margin-right: 80px;
    position: relative;
    padding-left: 20px;
    float: left;
}
.commentBubble { 
    position: absolute;
    top: 6px;
}

.postactioncom{
    position: relative;  
    float:left;
    cursor: pointer;
}
.postcct {
    color: #4aa6de; /*#4d4d4d; */
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.06px; 
    line-height: 20px; 
    margin-left: 25px; 
    vertical-align: middle;
    cursor: pointer;
}
    
.postacc_t {
    float: right; 
    display: flex; 
    align-items: center;
}

.post_comm_cnttext {
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.06px; 
    line-height: 20px;
    position:absolute; 
    top: 13px;
    right: 40px;
}

.likeThumb {
    position: absolute;
    top: 12px; 
    right:20px;
    width: 15px;
    height: 15px;
}


.replypad {
    padding-left: 60px;
}

.dbbl34 {
    position:relative;
    float:left;
}

.mnblkauto {
    height:calc(100vh - 120px);
    height:-moz-calc(100vh - 120px);
    height:-webkit-calc(100vh - 120px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.mnblknoscroll {
    height:calc(100vh - 120px);
    height:-moz-calc(100vh - 120px);
    height:-webkit-calc(100vh - 120px);
    overflow-x: hidden;
    /* overflow-y: hidden; */
    -webkit-overflow-scrolling: touch;
}

.mnblk {
    margin-left: 6%;
    top: 18%;
}

.commTextCreate {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.32px;
    color: #495057;
    background-color: #eff1f3;
    background-clip: padding-box;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border:none;
    overflow: auto;
    resize: none;
    height: unset;
}

.commEditBoxHide {
    visibility: hidden;
}

.commEditBoxShow {
    visibility: visible;
}

.replyEditBoxHide {
    visibility: hidden;
}

.replyEditBoxShow {
    visibility: visible;
}

.postHdr {
    height: 44px;
    background-color: #0d171f;
}

.imgContainer {
    padding: 8px 0 8px 10px;
    width: fit-content;
}

.imgPreviewBox {
    border: 2px solid #d3d3d3;
    border-radius: 6px;
    position: relative;
}

.imgPreview {
    width: 100px;
    height: 60px;
    object-fit: contain;
}

.cancelImgBtn {
    position: absolute;
    left: 67px;
    bottom: 31px;
}

.imageopaque {
    height: 60px;
    width: 100%;
    position: absolute;
    background-color: #dcdada;
}

.commMedia {
    margin-left: 60px;
    padding-top: 10px;
    padding-bottom: 8px;
}

.commentImg {
    width: 70%;
    border-radius: 10px;
}

.video-react .video-react-big-play-button {
    font-size: 2em;
    line-height: 1.5em;
    height: 1.5em;
    width: 3em;
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    border: 0.06666em solid #fff;
    background-color: #2B333F;
    background-color: rgba(43, 51, 63, 0.7);
    border-radius: 0.3em;
    transition: all 0.4s;
}

.moreButton {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin-left: 5%;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.32px;
    line-height: 19px;
    white-space: pre-wrap; 
    margin-bottom: 50px; 
}

.grouplist {
    height: calc(100vh - 80px)
}

/* on desktop settings */
@media screen and (min-width:800px) {
    .margintop{
        margin-top: 100px;
    }
    .margintop84 {
        margin-top: 14px;
    }
    .blogMainPost {
        width: 50%;
        margin-bottom: 10px;
    }
    .blogMainPostHeader {
        width:44%
    }

    .commWrapper {
        position: absolute;
        left: 0;
        bottom: 10px;
        width: 100%;
        padding: px 4px 4px 4px;
        display: inline-block;
        background-color: #172734;
    }
    .replyWrapper {
        position: absolute;
        left: 0;
        bottom: 0px;
        width: 100%;
        padding-left: 4px;
        padding-bottom: 4px;
        text-align: left;
        display: inline-block;
        background-color: #172734;
    }
    .replyBox {
        background-color: #172734;
    }

    .commInput {
        width: 78%;
        margin-left: 10%;
        background-color: #eeeeee;
        border-radius: 5px;
    }
    .commformbtn {
        bottom: 0px;
        position: absolute;
        margin-left: 88%;
    } 
    .replyInput {
        width: 76.5%;
        color: #1f1f9d;
        margin-left: 50px;
        border: 0;
        background: #eeeeee;
        box-sizing: border-box;
        border-radius: 5px;
    }
    .replyname {
        color: #ffffff;
        border-radius: 0.4rem;
        padding-left: 50px;
        border: 1px solid #172734;
        background-color: #172734;
        ;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.32px;
        padding-top: 5px;
        padding-bottom: 4px;
    }
    .replyformbtn {
        bottom: 0px;
        position: absolute;
        margin-left: 88%;
    } 
    .imgContainerComm {
        padding: 1px 0px 4px 0px;
        width: fit-content;
        margin-left: 10%;
    }
    .notifyPost {
        margin-bottom: 0px;
    }
  }
  
  /* on mobile settings */
@media screen and (max-width:799px) {
    .margintop84{
        margin-top: 14px;
    }
    .margintop {
        margin-top: 84px;
    }
    .blogMainPost {
        width: 100%;
        margin-bottom: 10px;
    }
    .blogMainPostHeader {
        width:95%
    }
    .commWrapper {
        position: absolute;
        left: 0px;
        bottom: 8px;
        width: 100%;
        padding: 0px 4px 6px 4px;
        display: inline-block;
        background-color: #172734;
    }
    .commWrapperInFocus {
        position: absolute;
        left: 0px;
        bottom: -52px;
        width: 100%;
        padding: 0px 4px 6px 4px;
        display: inline-block;
        background-color: #172734;
    }
    .replyWrapper {
        position: absolute;
        left: 0;
        bottom: 8px;
        width: 100%;
        display: inline-block;
        background-color: #172734;
        padding-bottom: 6px;
    }
    .replyWrapperInFocus {
        position: absolute;
        left: 0;
        bottom: -52px;
        width: 100%;
        display: inline-block;
        background-color: #172734;
        padding-bottom: 6px;
    }
    .replyBox {
        background-color: #172734;
        border-radius: 10px;
        width: 72%;
    }
    
    .commInput {
        width: 74%;
        height: 35px;
        margin-left: 15%;
        background: #eeeeee;
        box-sizing: border-box;
        border-radius: 5px;
    }
    .commformbtn {
        bottom: 0px;
        position: absolute;
        margin-left: 86%;
    } 
    .replyInput {
        width: 104%;
        height: 40px;
        margin-left: 56px;
        /* float: left; */
        color: #1f1f9d;
        border: 0;
        background: #eeeeee;
        box-sizing: border-box;
        border-radius: 5px;
        margin-top: 3px;
    }
    .replyname {
        color: #ffffff;
        width: 100%;
        position: absolute;
        padding-left: 56px;
        top: -28px;
        border: 1px solid #172734;
        background-color: #172734;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.32px;
        padding-top: 5px;
        padding-bottom: 4px;
        text-align: left;
    }
    .replyformbtn {
        bottom: 0px;
        position: absolute;
        margin-left: 87%;
    } 
    .imgContainerComm {
        padding: 1px 0 4px 48px;
        width: fit-content;
    }
    .notifyPost {
        margin-bottom: 50px;
    }
  } 
  
  .bookmarkcontainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid #172734;
    padding: 10px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
  }
  .bookmarkicon {
    padding-left: 10px;
  }
  .bookmarkimg {
    flex: 1;
    margin-right: 10px;
  }

  .bookmarkContent {
    flex: 4;
    color: #ffffff;
  }

  .bookmarkName {
    margin-bottom:8px;
    color: aquamarine;
}

.bookmarkcreated {
    color: #76bbbb;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.32px;
    line-height: 19px;
    white-space: pre-wrap;
    cursor: pointer;
}


.bookmarklinecontent {
    max-width: 100%;
    display: -webkit-box;
    overflow: hidden ;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    font-size: 12px;
    letter-spacing: -0.32px;
    line-height: 19px;
  }

  .groupinlinecontainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid #22394a;
    padding: 15px 10px 15px 10px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
  }

  .notifycontainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    margin-bottom: 1px;
  }

  /* Notify app related */
  .notifycontainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    margin-bottom: 1px;
  }

.commPhotoCamDisabled {
    margin-left: 5%;
    bottom: 10px;
    position: absolute;
    z-index: 2;
    float: left;
    width: 30px;
    height: 30px; 
    background-size: cover;
    background-repeat: no-repeat;
}

.commPhotoCam {
    margin-left: 5%;
    bottom: 10px;
    position: absolute;
    z-index: 2;
    float: left;
    width: 30px;
    height: 30px; 
    background-size: cover;
    background-repeat: no-repeat;
}


/* on desktop settings */
@media screen and (min-width:895px) {
    .blogMainPost {
        width: 100%;
        margin-bottom: 10px;
    }
    .blogMainPostHeader {
        width:77%
    }
}

@media screen and (max-width:894px) {
    .blogMainPost {
        width: 100%;
        margin-bottom: 10px;
    }
    .blogMainPostHeader {
        width:95%
    }
}

/* remove this ? */
.topDrawer {
    font-size: larger;
}

/* on desktop settings */
@media screen and (min-width:895px) {
    .postCreate {
        margin-top: 50px;
        height: -webkit-fill-available;
    }
}

/* on mobile settings */
@media screen and (max-width:894px) {    
    .postCreate {
        margin-top: 0px;
        margin-bottom: 30px;
        height: -webkit-fill-available;
    }
}


@media screen and (min-width:895px) {
    .slidesearch {
        margin-top: 50px;
        height: -webkit-fill-available;
        background-color: #ffffff;
    }
}

@media screen and (max-width:894px) {    
    .slidesearch {
        margin-top: 0px;
        height: -webkit-fill-available;
        background-color: #ffffff;
    }
}


.propic {
    border-radius: 10px;
    width: 200px;
}

.propiclock {
    float:left;
    padding-right: 10px;
}

.deleteBox {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    color: #FFF;
    padding: 50%;
    text-align: center;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

._3sAFzPEm {
    color: #ffffff;
}

.notificationalert{
    margin-bottom: 60px;
}

.bndhdr {
    background-color: #172734;
    padding: 3px 0 3px 10px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.bndhdrp {
    color: #ffffff;
    margin-bottom: 0px;
    line-height: 1.38105;
    letter-spacing: .011em;
    font-size: 0.9375rem !important;
}

.echdiv1 {
    display:flex; 
    flex-direction: row; 
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 18px;     
    border: solid #172734;
    background-color: #172734;
    margin-bottom:1px;
}

.echdiv2 {
    display: flex;
    justify-content: space-between;
    flex-shrink: 1;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.echdiv3 {
    margin-right: 12px;
    align-self: flex-start;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    margin-top: 8px;
}

.echdiv4 {
    color: #ffffff;
    display: flex; 
    justify-content: space-between; 
    flex-shrink: 1;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
}

.echdiv5 {
    flex-basis: 0px;
    padding-top: 12px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-shrink: 1;
    padding-bottom: 12px;
    flex-direction: column;
    flex-grow: 1;
}

.echdiv6 {
    word-break: break-word; 
    font-size: 0.9375rem;
    text-align: left;
    font-weight: 500;
}

.video-react .video-react-big-play-button {
    font-size: '2em' !important;
}

/* .css-kkraca-MuiFormControl-root-MuiTextField-root {
    width: 100%;
    border-radius: 10px;
    background-color: #EEEEEE;
} */

/* .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 6.5px 14px;
} */
