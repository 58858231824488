.slide-pane__content {
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 0 20px 0;
  flex: 1 1 auto;
  background-color: #0d171f;
}
  

.pswp img {
  max-width: none;
  object-fit: contain !important;
}

.slide-pane__header {
  display: flex;
  flex: 0 0 64px;
  align-items: center;
  background: #172734;
  height: 74px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.slide-pane__close {
  margin-left: 24px;
  padding: 55px 16px 16px 16px;
  opacity: 0.7;
  cursor: pointer;
}