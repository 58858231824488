.react-blueimp-thumbnails {
    display: grid;
    grid-gap: 2px;
    grid-template-columns: repeat(auto-fill, minmax(100px, 6fr));
    /* grid-template-columns: repeat(4, 1fr); */
    column-gap: 2px;
    row-gap: 2px;
}

.react-blueimp-thumbnails > a {
    border: 1px solid #aaa;
    /* border-radius: 5px; */
    /* margin: 0.5px; */
    /* height: 123px; */
    width: 33.33%;
    height: auto;
    position: relative;
    color: #FFF;
    float: left;
    overflow: hidden;
}

.react-blueimp-thumbnails > a > img {
    /* height: 100%; */
    width: 100%;
    vertical-align: middle;
    /* text-align: center; */
    /* position: relative; */
    /* color: #FFF; */
    /* float: left; */
    justify-content: stretch;
}


.react-blueimp-thumbnails > div > div > a > img {
    width: 100%;
    vertical-align: middle;
    /* text-align: center; */
    /* position: relative; */
    /* color: #FFF; */
    /* float: left; */
    justify-content: stretch;
}

