.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(12, 22, 31, 1);
    z-index: 3999;
    animation: popout 0.5s ease forwards;
}

@keyframes popout {
    0%{opacity: 0;}
    100%{opacity: 1;}
}