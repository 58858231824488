.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.slide-pane__header{
  color: #ffffff;
}

/* .ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.postCreateOverlay,
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.notificationOverlay,
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.ProfileTabsOverlay,
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.BlogDetailOverlay,
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.UserLikesOverlay, 
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.editedPostOverlay,
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.postFormOverlay,
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.editPostOverlay,
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.groupListAllOverlay, 
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.groupFeedOverlay,
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.groupMemberTabsOverlay,
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.GroupBlogDetailOverlay
{
  z-index: 2;
} */

.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.postCreateOverlay,
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.notificationOverlay,
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.ProfileTabsOverlay,
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.BlogDetailOverlay,
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.UserLikesOverlay, 
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.editedPostOverlay,
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.postFormOverlay,
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.editPostOverlay,
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.groupListAllOverlay, 
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.groupFeedOverlay,
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.groupMemberTabsOverlay,
.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.GroupBlogDetailOverlay
{
  z-index: 2;
}


.slide-pane__overlay.overlay-after-open {
  z-index: 2;
}


.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.formCreateOverlay {
  z-index: 3;
}

/* .ReactModal__Content.ReactModal__Content--after-open.slide-pane.slide-pane_from_bottom.postCreate
 {
  height: 100vh;
  margin-top: 0vh;
  z-index: 2;
} */