.popup {
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: popout 0.6s ease forwards;
}

.popup-inner {
    position: relative;
     padding: 32px;
     width: 100%;
     height: 100vh;
     max-width: 640px;
     background-color: #0C161F;
}

.popup-inner .close-btn {
    position: absolute;
    top: 70px;
    right: 16px;
}

@keyframes popout {
    0%{opacity: 0;}
    100%{opacity: 1;}
}