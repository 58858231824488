.creategroup {
    width: 90%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 1);
    position: fixed;
    top: 20vh;
    left: 5%;
    z-index: 4000;
    animation: popout 0.5s ease forwards;
}


@keyframes popout {
    0%{opacity: 0;}
    100%{opacity: 1;}
}

.creategroup__header {
    padding: 14px 10px 38px 18px;
    background: #0D171F;
    color: #fff;
}

.creategroup__header h4 {
    margin: 0;
    font-size: 1.24rem;
}

.creategroup__content {
    padding: 1rem;
    background: #0D171F;
}

.creategroup__actions {
    display: flex;
    justify-content: center;
    padding: 1rem;
    background: #0D171F;
}

@media (min-width: 768px) {
    .creategroup {
        width: 50rem;
        left: calc((100% - 50rem) / 2 );
    }
}